import React, { useState, useRef } from 'react';
import Cookies from 'js-cookie';
import './reuseables.css';

let api = require('./apiConfig');
const io = require("socket.io-client");
const {socket, socket_init} = require('./socketConnect');
api = api.api;

function Login() {
  const usernameRef = useRef(null);
  const passwordRef = useRef(null);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (event) => { 
    event.preventDefault();
    handleLogin();
  }

  const handleLogin = async() => {

    const username = usernameRef.current.value;
    const password = passwordRef.current.value;


    try { 
      const response = await fetch(`${api}/api/login`, {method: 'POST', headers: {'Content-Type': 'application/json'}, body: JSON.stringify({username: username, password: password})})
      if(response.status === 500){ 
        return setErrorMessage('Wrong Username or Password!')
      }
      const data = await response.json();
      Cookies.set('userid', data.data.id);
      Cookies.set('jwt', data.token);
      window.location.href = '/lead-management';


    }catch(error){ 
      return setErrorMessage('Down for maintenance!')
    }
  
  
  };
  const editInputFields = () => { 
    setErrorMessage('')
  }

  return (
    <form onSubmit = {handleSubmit}>
      <div className='center-div-flex'>
        <div className="flex-container-el ">
          <h2>Login</h2>
          <input type="text" className="regularTextInputLogin max-width margin-top-10px" autoComplete="off" placeholder='Username' ref={usernameRef} onChange={editInputFields}/>
          <input type="password" className="regularTextInputLogin max-width margin-top-10px" autoComplete="off" placeholder='Password' ref={passwordRef} onChange={editInputFields} />
          <button type="submit" className="green-submit-btnLogin margin-top-10px max-width" onClick={handleLogin} >Login🔐</button>
          <p data-testid="error_message" className="margin-top-10px color-red">{errorMessage}</p>
        </div>
      </div>
    </form>
  );
}

export default Login;
