import {React, useRef} from 'react'
import Cookies from 'js-cookie'
function AddNote({setCurrentPopup, toggleComponent,  currentHouseholdId, api, fetchNotes}) {

    const desc = useRef();
    const POST_NOTE = async(desc) => { 
        
        const response = await fetch(`${api}/api/notes?household_id=${currentHouseholdId}&user_id=${Cookies.get('userid')}`, { 
          method: "POST", 
          body: JSON.stringify({desc}),
          headers : { 
            'Content-Type': 'application/json',
          }
        }) 
        return response; 
    } 

    const CREATE_NEW_NOTE = async(desc) =>{ 
        if(desc){ 
            const response = await POST_NOTE(desc); 
            if(response.status === 500 ){ 
                alert('Server Error Creating Note')
            }else { 
                fetchNotes(api);
                toggleComponent(3);
                setCurrentPopup(null);
            }
        }
    }
        

  return (
    <div className='background-color-full-dark border-radius-10px popup' id = "add-notes"  >
        <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setCurrentPopup(null)} >❌</button></div>
            <div className='flex-container-el padding-20px'> 
            <h3 className='text-align-left '>Add New Note</h3>
            <textarea  className='background-color-full-dark regularTextInput margin-top-5px  input width-400px height-200px textarea' placeholder='Enter you note... ' ref={desc} ></textarea>
            <button type = "button" class = "button-default green-submit-btn margin-top-10px max-width " id = "add=driver-submit-btn" onClick = {() => CREATE_NEW_NOTE(desc.current.value)}>Add new note</button>
            </div>
    </div>
  )
}

export default AddNote

