import React, { useState, useRef } from 'react';
import Cookies from 'js-cookie';

function SearchResults({currentOwner, searchTerm, currentSelect, setStatus_Description, setOwner, setSearchTerm, currentPage, driverData, setCurrentHouseholdId, currentUserData, userIdAndFullName, setCheckedHouseholds, checkedHouseholds, setSwitchNumber , fetchData, setSearchType}) {
  
  const checkedHousehold = (e, household_id) => {
    const newCheckedHouseholds = new Set(checkedHouseholds);
    if (e.target.checked) {
      newCheckedHouseholds.add(household_id);
    } else {
      newCheckedHouseholds.delete(household_id);
    }
    setCheckedHouseholds(newCheckedHouseholds);
  };

  function formatPhoneNumber(phoneNumber) {
    const cleanedPhoneNumber = phoneNumber.replace(/\D/g, '');
    const formattedPhoneNumber = `(${cleanedPhoneNumber.substring(0, 3)}) (${cleanedPhoneNumber.substring(3, 6)}) (${cleanedPhoneNumber.substring(6)})`;
    return formattedPhoneNumber;
  }

  const handleStatusClick = async() => { 
      await fetchData(currentPage, searchTerm);
      return;

  }

  const handleLeadClick = async(household_id, user_id) => { 
    
    if(currentUserData[0].id !== user_id && currentUserData[0].role === "Agent"){ 
     return  alert('Not assigned to this household!')
    }
    setCurrentHouseholdId(household_id);
    Cookies.set('currentHouseholdId', household_id); 
    setSwitchNumber(0);
  }


  return (
    <>

      <div className='search-overflow-wrapper margin-top-20px '>
        <table className='max-width' id="search-results">
          <thead>
            <tr>
              {console.log(currentUserData[0].role)}
              <th className='width-5-percent'>Select</th>
              <th>Customer</th>
              <th>
                {currentUserData && 
                <select className='bg-color-black-1 max-width' ref = {currentSelect } onChange={() => {handleStatusClick()}} >
                  <option value = "" >Status</option>
                  <option value = "New">New</option>
                  <option value = "Quoted">Quoted</option>
                  <option value = "Quoted Hot">Quoted Hot</option>
                  <option value = "Called, No Contact">Called, No Contact</option>
                  <option value = "Called, LVM">Called, LVM</option>
                  <option value = "BAD NUMBER">BAD NUMBER</option>
                  <option value = "Do Not Call">Do Not Call</option>
                  <option value = "Not Elegible">Not Elegible</option>
                  <option value = "Price is too High">Price is too High</option>
                  <option value = "Policy in Force">Policy in Force</option>
                  <option value = "Cancelled">Cancelled</option>
                  <option value = "Terminated">Terminated</option>
                  <option value = "Contacted  Quoted & Call Back">Contacted  Quoted & Call Back</option>
                  <option value = "Not Interested">Not Interested</option>
                  <option value = "Try again in 6 months">Try again in 6 months</option>
                  <option value = "Followed Up">Followed Up</option>
                  <option value = "Closed">Closed</option>
                  <option value = 'Already with Allstate'>Already With Allstate</option>
                  <option value = 'Follow Up/Ready to Close.'>Follow Up/Ready to Close</option>
                </select> }
              </th>
              <th>
    
               <select className = "bg-color-black-1 max-width" 
                  onChange={() => {handleStatusClick()}} 
                  ref = {currentOwner}>
                   
                 
                    {/*If you are agent display only your ownership of leads in change owner options */}
                  { currentUserData && currentUserData[0].role === "Agent" && userIdAndFullName  ?  (
                    <option default value = {currentUserData[0].id}>{userIdAndFullName[currentUserData[0].id]}</option>
                  ):
                  userIdAndFullName && Object.keys(userIdAndFullName).map(user => ( 
                    <option value = {user}>{userIdAndFullName[user]}</option>
                  ))}
                   {/*If you are admin display all owners by default */ }
                   {currentUserData && currentUserData[0].role !== "Agent" ?  ( 
                     <option 
                     selected
                        value = ""> All Owners
                     </option>

                  ): ( 
                    <option 
                    
                       value = ""> All Owners
                    </option>
                  )
                  }
              
                  
                  </select>
           
              </th>
              <th>Latest Notes</th>
            </tr>
          </thead>

          <tbody id="search-results_table_body">
            {
            driverData && currentUserData ? (
              
              driverData.map((driver, i) => (
                <tr key={i}>
                  <td>
                    <input
                      type="checkbox"
                      checked={checkedHouseholds.has(driver.household_id)}
                      onChange={(e) => checkedHousehold(e, driver.household_id)}
                    />
                  </td>
                  <td onClick={() => { handleLeadClick(driver.household_id, driver.user_id)}}>
                    <label><b className='color-orange'>Customer Name: </b>{driver.firstname + ' ' + driver.lastname}</label>
                    <br />
                    <label><b className='color-orange'>Cellphone: </b>{formatPhoneNumber(driver.home_phone)}</label>
                    <br />
                    <label><b className='color-orange'>Date Created: </b>{new Date(driver.date_created).toLocaleDateString('en-US')}</label>
                  </td>
                  <td onClick={() => { handleLeadClick(driver.household_id, driver.user_id) }}>
                    {driver?.status}
                    <br />
                    {driver?.status_description}
                  </td>
                  <td onClick={() => { handleLeadClick(driver.household_id, driver.user_id) }}>
                    {driver.user_id ? userIdAndFullName[driver.user_id] : "No Owner"}
                  </td>
                  <td onClick={() => { handleLeadClick(driver.household_id, driver.user_id) }}>
                    {driver?.description}
                  </td>
                </tr>
              ))
            ): <div className='search-load'><img   className='load-size' src = "https://i.gifer.com/ZKZg.gif"/></div>}
          </tbody>
        </table>
      </div>
    </>
  );
}

export default SearchResults;
