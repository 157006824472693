import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';
import ChatBox from './chatBox';
import './navbar.css';
import io from "socket.io-client";
import Add_story from './leads_page_popups/Add_story';
import { useSelector, useDispatch} from 'react-redux'; 
import { set_true, set_false} from './redux/firstSlice'; 
let { api } = require('./apiConfig');
const { getAnnouncement} = require('./fetches/fetch');


function Navbar({  setSwitchNumber, allUserData, currentUserData, userIdAndFullName, setCurrentPopup ,currentPopup,  }) {
    const [navBarPopUpVisible, setNavBarPopUpVisible] = useState(false);
    const [showChatBox, setShowChatBox] = useState(false);
    const [chatBoxUserData, setChatBoxUserData] = useState();
    const [userPreviousMessages, setUserPreviousMessages] = useState();
    const [receiverPreviousMessages, setReceiverPreviousMessages] = useState();
    const [chatMessages, setChatMessages] = useState();
    const [socket, setSocket] = useState();
    const [notificationCount, setNotificationCount] = useState({});
    const [chatDisplayName, setChatDisplayName] = useState();
    const [fromUserIDS, setFromUserIDS] = useState([]);
    const [fromUserID, setFromUserID] = useState();
    const [notificationData, setNotificationData] = useState();
    const [userNotificationData, setUserNotificationData] = useState();
    const [ currentSelectedUserId, setCurrentSelectedUserId] = useState();
    const [announcement, setAnnouncement] = useState();


    const dispatch = useDispatch();

/* Bad code please refactor */
    const popups = [
        null, 
        null, 
        null, 
        null, 
        null, 
        null, 
        null, 
        null, 
        null, 
        <Add_story setCurrentPopup = {setCurrentPopup} /> 
      ]

    const navigate = useNavigate();

    let userid = Cookies.get('userid');
    const handleLogout = () => {
        // Delete the JWT cookie
        Cookies.remove('jwt');
        Cookies.remove('userid');

        navigate('/');
    };

    const toggleNavBarPopUp = () => {
        setNavBarPopUpVisible(!navBarPopUpVisible);
    };

    const toggleTeamsPopup = () => { 
        document.getElementById('teams-popup').hidden = !document.getElementById('teams-popup').hidden


    }

    const fetchAllUserNotifications = async(to_user_id, from_user_id) => { 
        const response = await fetch(`${api}/api/notifications?toUserId='${to_user_id}'&fromUserId='${from_user_id}'`)
        const data = await response.json();
        setNotificationData(data);
    }
    

    const deleteNotifications = async(to_user_id, from_user_id) => { 
        const response = await fetch(`${api}/api/notifications?toUserId='${to_user_id}'&fromUserId='${from_user_id}'`, { 
            method: "DELETE"
        })

        if(response.ok){ 
            document.title = 'Agentz'
            fetchAllUserNotifications(to_user_id, from_user_id);
            console.log('Message seen');
            
        }
    }

    const  getNotiifcations = async() => { 
        const response = await fetch(`${api}/api/notification/user?toUserId=${userid}`, {
            headers: { 
                Authorization : Cookies.get('jwt')
            }
        });
        const data = await response.json();
        if(data.length  !== 0 ){ 
            document.getElementById('teams-popup').hidden = false; 
            document.title = 'Agetnz - New Message!'
        }
        if(response.ok){ 
        
            setUserNotificationData(data);
          
        }else { 
            Cookies.remove('jwt')
           window.location.href = '/';
        }
    }


    useEffect(() => {
        const intervalId = setInterval(() => {
            getNotiifcations();
        }, 3000);
        return () => clearInterval(intervalId);
      }, [api]);

   
   useEffect(()=> { 
    
    if(!socket) {
        setSocket(io("https://webpupsubforsocketio.webpubsub.azure.com", {
            path: "/clients/socketio/hubs/Hub",
        })) }
        if(socket){ 
            socket.on('connect', () => { 
                socket.emit('userConnected', userid);
            })
            socket.on("receiveMessages", (messages, fromUserId, toUserId) => { 
                fetchAllUserNotifications(toUserId, fromUserId);
                if(toUserId == Cookies.get('userid')){
                    setFromUserIDS((previousIds) => ([...previousIds, fromUserId]))
                    
                }
              });
              document.getElementById('teams-popup').hidden = false; 
        }
    },[socket, userid])

    useEffect(()=>{ 
        const fetchAnnouncement = async () => {
            try {
                const response = await getAnnouncement();
                const data = await response.json();
                setAnnouncement(data);
            } catch (error) {
                console.error('Error fetching announcement:', error);
            }
        };

        fetchAnnouncement();

        
        const intervalId = setInterval(fetchAnnouncement, 300000); 
        return () => clearInterval(intervalId);
    }, [api])
   

    


    const getPrivateMessages = async(sender, receiver) => {
        const userDataResponse = await fetch(`${api}/api/private-chats?sender=${sender}&receiver=${receiver}`);
        const userData = await userDataResponse.json();
    
        setUserPreviousMessages(userData);
      
    
        const receiverDataResponse = await fetch(`${api}/api/private-chats?sender=${receiver}&receiver=${sender}`);
        const receiverData = await receiverDataResponse.json();
    
        setReceiverPreviousMessages(receiverData);
       
    
        // Assuming each message object has a unique ID
        let chatHistory = [...userData, ...receiverData ];
        
    
        // Convert the object into an array of values
        let chatEntries = chatHistory;
    
        // Sort the array by date_created (assuming date_created is a property in each entry)
        chatEntries.sort((a, b) => new Date(a.date_created) - new Date(b.date_created));

        setChatMessages(chatEntries);
    
        
    }
   
    const handleUserClick = (userData) => {
        setCurrentSelectedUserId(userData.id);
        
        setShowChatBox(true);
        setChatBoxUserData(userData.id);
        getPrivateMessages(userid, userData.id);
        setChatDisplayName(userData.full_name);
        setFromUserID(userData.id);
        deleteNotifications(Cookies.get('userid'), userData.id);
        console.log(currentSelectedUserId); // Directly use userData.id instead of fromUserID
    };





    

   

    return (
        <>
      
             <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@20..48,100..700,0..1,-50..200" />
            {showChatBox ? <ChatBox currentSelectedUserId = {currentSelectedUserId} setShowChatBox = {setShowChatBox} chatBoxUserData = {chatBoxUserData} socket= {socket}  chatMessages = {chatMessages} getPrivateMessages= {getPrivateMessages} notificationCount = {notificationCount} setNotificationCount = {setNotificationCount} chatDisplayName = {chatDisplayName} showChatBox = {showChatBox} fromUserID={fromUserID} setFromUserID = {setFromUserID} userIdAndFullName = {userIdAndFullName} />  : null }
            <nav className="navbar position-sticky ">
                <button className = "max-height" type="button" id='nav-popup-menu-btn' onClick = {()=> dispatch(set_true())}>☰</button> 
                <div className=' overflow-x width-700px light-dark-background flex-center-hor '><label className= "light-dark-background ticker  max-width ">{ userNotificationData && userNotificationData.length > 0 ? "New message" : announcement && announcement.announcement_desc}</label></div>
                <div className='light-dark-background '  >
                <button className = "max-height " type="button" id="logout-btn"  data-testid = "teams-popup" onClick= {() => toggleTeamsPopup()} ><h5 className='light-dark-background '>Teams</h5></button>
                <button className = "max-height" type="button" id="logout-btn" data-testid="logout-btn" onClick= {() => {handleLogout(); }}><h5 className=' light-dark-background'>Logout</h5></button>
                </div>
            </nav>

            <div className='position-absolute margin-left-1775px z-index ' id="teams-popup" data-testid="teams-popup-display" hidden>
                <ul className='light-dark-background '>
                    {allUserData ? allUserData.map((userData, index) => (
                        
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer margin-top-5px display-flex'>
                            <li className='light-dark-background padding-left-2px inline-el ' data-testid="chat-btn" onClick={() => handleUserClick(userData)}>
                                {userData.id === userid ? null : userData.full_name}
                                {userNotificationData && userNotificationData.some(notification => notification.from_user_id === userData.id) && (
                                    <span className="notification-count color-red light-dark-background">New</span>
                                   
                                )}
                            </li>
                        </div>
                    )) : null}
                </ul>
            </div>


            {currentUserData && navBarPopUpVisible  && currentUserData[0]?.role === "Admin"  ? (
                <div className='light-dark-background width-200px position-absolute' id="navbarpopup">
                    <ul className='light-dark-background '>
                    <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer margin-top-5px display-flex'><span class="material-symbols-outlined " ></span><li className='light-dark-background padding-left-2px inline-el fade-in' onClick={() => setSwitchNumber(0)}>Home</li></div>
                    <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><span className="material-symbols-outlined light-dark-background display-block" ></span><li className='light-dark-background padding-left-2px inline-el fade-in' onClick={() => setSwitchNumber(7)} >Dashboard</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer margin-top-5px display-flex'><span class="material-symbols-outlined " ></span><li className='light-dark-background padding-left-2px inline-el fade-in' onClick={() => setSwitchNumber(1)}>Leads</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><span className="material-symbols-outlined light-dark-background display-block " ></span><li className='light-dark-background padding-left-2px inline-el fade-in' onClick={() => setSwitchNumber(2)}>Pipeline</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><span className="material-symbols-outlined light-dark-background display-block" ></span><li className='light-dark-background padding-left-2px inline-el fade-in ' onClick={() => setSwitchNumber(3)}>User Management</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><span className="material-symbols-outlined light-dark-background display-block" ></span><li className='light-dark-background padding-left-2px inline-el fade-in' >Work Station</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><span className="material-symbols-outlined light-dark-background display-block" ></span><li className='light-dark-background padding-left-2px inline-el fade-in' onClick = { () => setSwitchNumber(5)} >AgentZ Resources</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><span className="material-symbols-outlined light-dark-background display-block" ></span><li className='light-dark-background padding-left-2px inline-el fade-in'>AgentZ Management</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><span className="material-symbols-outlined light-dark-background display-block" ></span><li className='light-dark-background padding-left-2px inline-el fade-in' onClick={() => setSwitchNumber(4)} >Tech Support</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><span className="material-symbols-outlined light-dark-background display-block" ></span><li className='light-dark-background padding-left-2px inline-el fade-in' >Providers</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><span className="material-symbols-outlined light-dark-background display-block" ></span><li className='light-dark-background padding-left-2px inline-el fade-in' >Charts</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><span className="material-symbols-outlined light-dark-background display-block" ></span><li className='light-dark-background padding-left-2px inline-el fade-in' onClick={() => setSwitchNumber(6)} >Calendar</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><span className="material-symbols-outlined light-dark-background display-block" ></span><li className='light-dark-background padding-left-2px inline-el fade-in' onClick={() => setCurrentPopup(9)} >Add Announcement</li> </div>
                    </ul>
                </div>
            ): currentUserData && navBarPopUpVisible  && currentUserData[0]?.role === "Supervisor"  ? ( 

                <div className='light-dark-background width-200px position-absolute' id="navbarpopup">
                    <ul className='light-dark-background '>
                    <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer margin-top-5px display-flex'><li className='light-dark-background padding-left-2px inline-el fade-in ' onClick={() => setSwitchNumber(0)}>Home</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer margin-top-5px display-flex'><span class="material-symbols-outlined fade-in " >assignment_ind</span><li className='light-dark-background padding-left-2px inline-el ' onClick={() => setSwitchNumber(1)}>Leads</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><li className='light-dark-background padding-left-2px inline-el fade-in ' onClick={() => setSwitchNumber(2)}>Pipeline</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><li className='light-dark-background padding-left-2px inline-el fade-in ' >Work Station</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><li className='light-dark-background padding-left-2px inline-el fade-in'>AgentZ Management</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><li className='light-dark-background padding-left-2px inline-el fade-in' onClick={() => setSwitchNumber(4)} >Tech Support</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><li className='light-dark-background padding-left-2px inline-el fade-in' >Charts</li></div>
                    </ul>
                </div>

            ): currentUserData && navBarPopUpVisible  && ( 
                currentUserData[0]?.role === "Agent" || currentUserData[0]?.role === "Customer Service" ?( 
                <div className='light-dark-background width-200px position-absolute' id="navbarpopup">
                    <ul className='light-dark-background '>
                    <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer margin-top-5px display-flex'><li className='light-dark-background padding-left-2px inline-el fade-in ' onClick={() => setSwitchNumber(0)}>Home</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer margin-top-5px display-flex'><li className='light-dark-background padding-left-2px inline-el fade-in' onClick={() => setSwitchNumber(1)}>Leads</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><span className="material-symbols-outlined light-dark-background display-block" ></span><li className='light-dark-background padding-left-2px inline-el fade-in' onClick={() => setSwitchNumber(7)} >Dashboard</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><li className='light-dark-background padding-left-2px inline-el fade-in' onClick={() => setSwitchNumber(2)}>Pipeline</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><li className='light-dark-background padding-left-2px inline-el fade-in' onClick={() => setSwitchNumber(4)} >Tech Support</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><li className='light-dark-background padding-left-2px inline-el fade-in' onClick = { () => setSwitchNumber(5)} >AgentZ Resources</li></div>
                        <div className='light-dark-background margin-left-5 margrin-bottom-5px hover-cursor-pointer display-flex'><li className='light-dark-background padding-left-2px inline-el fade-in' >Charts</li></div>
                    </ul>
                </div>

            ): null
             )}
             {currentPopup && popups[currentPopup]}
        </>
    );
}

export default Navbar;
