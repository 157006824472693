import React from 'react'
import { useSelector, useDispatch } from 'react-redux'; 
import {toggle} from './redux/toggleSettings'
import Settings from './sidebar_popups/Settings.tsx'
function Sidebar({setSwitchNumber, currentUserData, setToggleAddAnnouncement }) {
  let user;
  interface toggleSettings { 
    toggleSettings: { 
      toggle:boolean;
    }
  }
  const dispatch = useDispatch();
 
  if(currentUserData){ 
    user = currentUserData[0]
  }

  const displaySettings= useSelector((state:toggleSettings) => state.toggleSettings.toggle);
  const handleSettingsToggle = () =>{ 
    dispatch(toggle())
  }

  return (
   <>
    {displaySettings && <Settings />}
   <div className=' bg-color-black-1 max-height-sb flex-across-space-between flex-column-no-ho-centered '> 
        <div className='bg-color-black-1 flex-column-no-ho-centered '>
         
          <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" onClick={() => setSwitchNumber(7)}>Dashboard </span>
          <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" onClick={() => setSwitchNumber(1)}>Leads </span>
          <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" onClick={() => setSwitchNumber(2)}> Pipeline </span>
          {user &&  user.role === "Admin"  ?  <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" onClick={() => setSwitchNumber(3)}>User Management</span> : null} 
          <span className = "padding-10px cursor-pointer fade-in bg-color-black-1">Work Station</span>
          <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" onClick={() => setSwitchNumber(5)}>AgentZ Resources</span>
          {user && user.role === "Admin" ? <span className = "padding-10px cursor-pointer fade-in bg-color-black-1">AgentZ Management</span> : null }
          <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" onClick={() => setSwitchNumber(4)}>Tech Support</span>
          <span className = "padding-10px cursor-pointer fade-in bg-color-black-1">Providers</span>
          <span className = "padding-10px cursor-pointer fade-in bg-color-black-1">Charts</span>
          <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" onClick={() => setSwitchNumber(6)}>Calendar</span>
          {user && user.role === "Admin" ? <span className = "padding-10px cursor-pointer fade-in bg-color-black-1" onClick={() => setToggleAddAnnouncement(true)}>Add Announcement</span> : null}
        </div>
        <div className='bg-color-black-1 flex-column-no-ho-centered'>
          <span className='padding-10px fade-in bg-color-black-1 cursor-pointer' onClick={handleSettingsToggle}>{currentUserData && currentUserData[0].full_name}</span>
         <span className='padding-10px fade-in bg-color-black-1 font-size-12px '>Agentz by SquareWealth LLC©</span>
         </div>
    </div>
    </>
  )
}

export default Sidebar
