import {React, useState} from 'react';
import './driverCard.css';
import avatar from './images/avatar.png'

function DriverCard({ primaryDriver, key, firstname, middlename, lastname, relation, dateOfBirth, vehicleData, slide, gender, togglePopUpByEl, id, setCurrentPopup = {setCurrentPopup}, phone_number  }) {
  const [selectedDriver, setSelectedDriver] = useState(null);


    



  return (
<>


    
    <div className={`flex-column-no-ho-centered  light-dark-container  `} name = {selectedDriver} onClick={() => setCurrentPopup(3) }>
    
      <div className="light-dark-container flex-center-column margin-left-10 min-width-185px box-border ">
        <img src= {avatar} className='light-dark-container' id="avatar-img" alt="avatar"  />
        <label className="light-dark-container">{firstname + ' ' + (middlename !== 'null' && middlename ? middlename : '') + ' ' + lastname}{primaryDriver === true ? <span className = "light-dark-container text-align-right-new">*</span>: null}</label>
        <label className="light-dark-container">{dateOfBirth}</label>
        <label className="light-dark-container">{relation}</label>
        <label className="light-dark-container">{gender}</label>
        <label className="light-dark-container">{phone_number}</label>
      </div>
      
    
      <div className="light-dark-container display-flex">
        {Array.isArray(vehicleData) && vehicleData.length > 0 ? (
          vehicleData.map((vehicle, index) => (
            <div key={index} className="light-dark-container box-border flex-center-column margin-left-10 min-width-185px margin-top-10px">
              <label className="light-dark-container">{vehicle.year}</label>
              <label className="light-dark-container">{vehicle.make}</label>
              <label className="light-dark-container">{vehicle.model}</label>
              <label className="light-dark-container">{vehicle.vin}</label>
            </div>
          ))
        ) : (
          // Render an empty div when vehicleData is not available
          <div className="light-dark-container "></div>
        )}
      </div>
    </div>
    </>
  );
}

export default DriverCard;
