import React, {useEffect, useState} from 'react'
import Cookies from 'js-cookie';
const {getPoliciesByUserId} = require('../fetches/fetch');

function PoliciesRelatedToUserPopup({setTogglePoliciesPopup, userIdAndFullName, setCurrentHouseholdId, setSwitchNumber}) {
    const [policies, setPolicies] = useState();

    useEffect(() => { 
        const asyncFunc = async() => { 
        
            const response = await getPoliciesByUserId(Cookies.get('userid'));
            const data = await response.json();
            setPolicies(data);

        }
        asyncFunc();
    }, [])

   
  return (
    <>
    <div className='background-color-full-dark border-radius-10px popup z-index '>
        <div className = 'black-background  text-align-right border-radius-top-10px  '>
            <button type = "button" className='popup-exit-button' onClick = {()=> setTogglePoliciesPopup(false)}  >❌</button>
        </div>

        <div className=' padding-20px light-dark-background followups-table '> 
           
            <table>
                        <th>Primary Driver Name</th>
                        <th>Policy Number</th>
                        <th>Policy Type</th>
                        <th>Date Bound</th>
                        <th>Producer</th>
                        <th>Company</th>
                        <th>Effective Date</th>
                        <th>Status</th>
                        <th>Prem Total</th>
                        <tbody id="search-results_table_body"> 
                        {policies ? policies.map(policy => (
                            <>
                            <tr onClick = {() => {Cookies.set('currentHouseholdId', policy.household_id[0]); setCurrentHouseholdId(policy.household_id[0]); setSwitchNumber(0) }}>
                                <td>{policy.firstname + " " + policy.lastname}</td>
                                <td>{policy.policy_id_two}</td>
                                <td>{policy.policy_type}</td>
                                <td>{new Date(policy.date_created[0]).toLocaleDateString('en-US', {timeZone: 'UTC'})}</td>
                                <td>{userIdAndFullName[policy.user_created_id]}</td>
                                <td>{policy.company}</td>
                                <td>{new Date(policy.effective_date ).toLocaleDateString('en-US', {timeZone: 'UTC'})}</td>
                                <td>{policy.status}</td>
                                <td>${policy.total_prem}</td>
                            </tr>
                            </>
                        )): null}
                        </tbody>
                    </table>
            

        </div>
    </div>
    </>
  )
}

export default PoliciesRelatedToUserPopup
