import React, { useEffect, useState, useRef } from 'react';
import Calender_popup from './calender_popup/Calender_popup';
import {patchCompleteTasks} from '../src/fetches/fetch'
import Cookies from 'js-cookie';
let api = require('./apiConfig');
api = api.api;
const jwt = Cookies.get('jwt');




function Leftside({ setSearchTerm,  setSwitchNumber, setCurrentHouseholdId, getTasks, tasks, recentCustomers, gg}) {
  const [latestDrivers, setLatestDrivers] = useState(null);
  const searchTermValue = useRef<any>(null);

  interface recentCustomers { 
    household_id: number; 
    firstname: string;
    lastname: string;

  }
  
  function removeDuplicateObjects(arr) {
    
    arr = arr.slice(arr.length - 20,arr.length)

    let uniqueSet = new Set(arr.filter(obj => obj).map(obj => JSON.stringify(obj)));  // Use JSON.stringify for value comparison
    return Array.from(uniqueSet).map(str => JSON.parse(str));  // Convert back to objects
  }

  const removedDupes =  removeDuplicateObjects(recentCustomers)
  const reversedRemovedDupes = [...removedDupes].reverse();

  


  async function fetchHouseholdsBelongingToUser() {
    const response = await fetch(`${api}/api/latestdrivers?amount=10&user_id=${Cookies.get('userid')}`, {
      headers: {
        Authorization: jwt,
      },
    });
    const data = await response.json();
    setLatestDrivers(data);
    
    
  }





  useEffect(() => {
    fetchHouseholdsBelongingToUser();
    getTasks();
   
   
    
    
  }, []);

  const removeHyphens = (string: string) => { 
    return string.replace(/-/g, '')
  }


  const changeCurrentHouseholdId = (id) => {
    setCurrentHouseholdId(id)
    Cookies.set('currentHouseholdId' , id)
    
  }
  const renderSearchResults= () => { 
    if(searchTermValue.current && searchTermValue.current.value){
      let noHyphens = removeHyphens(searchTermValue?.current?.value);
    setSearchTerm(noHyphens.trim());
    } 
    setSwitchNumber(1);
 
  }

  const onKeyDownEnter = (e) => { 
  
    if(e.keyCode ===  13 ){ 
      
      if(searchTermValue.current && searchTermValue.current.value){
        let noHyphens = removeHyphens(searchTermValue?.current?.value);
        setSearchTerm(noHyphens.trim());
        } 
        setSwitchNumber(1);

    }
  }
  const handleCompleteTask = async(task_id) => { 
    
    const response : any = await patchCompleteTasks(task_id); 
    if (response.status  === 200){ 
      getTasks();
    }
  }
  

  return (
<>
   
   <div className = " max-width-180 max-height ">

    <div className = "light-dark-container " id = "upcoming-assignments">
      <h3 className= "light-dark-container">Search customer</h3> 
      <div className='flex-across regularTextInput width-95-percent '> 
        <input type = "text" className=' no-border outline-none  max-width' id = 'search-driver-input' ref ={searchTermValue} onKeyDown={(e) => onKeyDownEnter(e) } />
        <button type = "button" className = " margin-left-5  no-border" id = "left_panel_search_button "  onClick={()=> renderSearchResults()}>🔍</button>
      </div>
      
    </div>
      
    <div className = "light-dark-container margin-top-10px stretch ">
      <h3 className= "light-dark-container">Recent Customers</h3> 
      <div className = "  light-dark-container">
        {recentCustomers? 
        reversedRemovedDupes.slice(0,8).map((recentCustomers) => 
        <li className ="light-dark-container display-block block-display cursor-pointer hover"
         onClick={() => {changeCurrentHouseholdId(recentCustomers.household_id[0]); setSwitchNumber(0);}} >
        {recentCustomers?.organization && recentCustomers?.organization !== 'null' ? recentCustomers.organization : recentCustomers?.firstname + ' ' + recentCustomers?.lastname }</li> ) 
        : <a href ="#" className ="light-dark-container">No drivers to display</a> }
      </div>
    </div>

    <div className = "light-dark-container margin-top-10px stretch overflow-height  word-wrap " id = "upcoming-assignments ">
      <h3 className= "light-dark-container  ">Upcoming appointments</h3> 
      {tasks && tasks.map(task => ( 
      <div className='flex-across light-dark-background'>
        { task && task.is_completed === false ? (
        <li className ="light-dark-container display-block block-display cursor-pointer hover border-box-ua "   
          onClick = {() => {
            Cookies.set('currentHouseholdId', task.household_id);
            setCurrentHouseholdId(task.household_id); setSwitchNumber(0)}}>
                 <span className='flex-across-space-between light-dark-container' >
                 {task.task_desc 
                 + " - "
                 + new Date(task.task_date).toLocaleDateString('en-US', {hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: true,  month: 'long', day: 'numeric', year: 'numeric'})}
                </span>
          </li>
               ): null}
             {task.is_completed === false ? <button type = "button" className='green-submit-btn' onClick={() => handleCompleteTask(task.task_id)} >Complete</button> : null}
      </div>
      ))}
      
    </div>
   </div>
    </>
  );
}

export default Leftside;