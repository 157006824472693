const{api} = require('../apiConfig.js')

const postAnnouncement = async(announcement) => { 
    const response = await fetch(`${api}/api/announcement`,{
        method: "POST",
        body: JSON.stringify({ 
            'announcement' : announcement
        }), 
        headers: { 
            'Content-Type': 'application/json'
        }
    })
    return  response;
}

const getAnnouncement = async() => { 
    const response = await fetch(`${api}/api/announcement`, { 
        method: "GET"
    })
    return response;
}

const getCount = async() => { 
    const response = await fetch(`${api}/api/driver/count`, { 
        method: "GET"
    })
    return response;
}

const getFollowUps = async(user_id) => { 
    let response;
    if(user_id){ 
         response = await fetch(`${api}/api/household/follow_ups?user_id=${user_id}`, { 
            method: "GET", 
        })
    }else { 
        response = await fetch(`${api}/api/household/follow_ups`, { 
            method: "GET", 
        })
    }

    return response;
}

const patchCompleteTasks = async(task_id) => { 
    try{ 
      const response = await fetch(`${api}/api/task/complete?task_id=${task_id}`, { 
        method: "PATCH"
      })
      return response;
    }catch(error){ 
        return alert('Network error!')
    }
}

const postOrganization = async(household_id, org_name) => { 
    try{ 
        const response = await fetch(`${api}/api/household/organization`, { 
            method: "POST", 
            headers: { 
                'Content-Type': 'application/json'
            }, 
            body: JSON.stringify({ 
                'org_name': org_name,
                'household_id': household_id
            })
        })
        return response;
    }catch(error){ 
        return alert('Network error')
    }
}

const editHouseholdInfo = async(household_id, household_contact_info) => { 
    try{ 
       return await fetch(`${api}/api/household/edit`, { 
            method: "POST", 
            headers:{ 
                'Content-Type': 'application/json'
            }, 
            body: JSON.stringify({
                'household_id': household_id,
                'household_contact_info': household_contact_info
            })
        })
        
    }catch(error){ 
        return console.error(error);
    }
}

const setPrimaryDriver = async(driver_id, household_id)=> { 
    try{ 
        return await fetch(`${api}/api/drivers/primary`, { 
            method: "POST",
            headers: { 
                'Content-Type': 'application/json'
            }, 
            body: JSON.stringify({
                "driver_id": driver_id, 
                "household_id": household_id
            })
        })
        
    }catch(error){ 
        console.error(error)
    }
}

const updatePolicy = async(policy_obj) => { 
    
    try{ 
        return await fetch(`${api}/api/policy/update`, { 
            method: "PATCH", 
            headers: { 
                "Content-Type": "application/json"
            },
            body: JSON.stringify({'policy':  policy_obj})
        })
    }catch(error){ 
        console.error(error);
    }
}

const getChildOrgs = async(household_id) => { 
    try{ 
        return await fetch(`${api}/api/household/orgs`, { 
            method: "POST", 
            headers: { 
                "Content-Type": "application/json"
            }, 
            body: JSON.stringify({
                'household_id': household_id
            })
        })
    }catch(error){ 
        console.error(error);
    }
}

const getPoliciesByUserId = async(user_id) => { 
    try{ 
        return await fetch(`${api}/api/dashboard/monthly_policies?user_id=${user_id}`);
    }catch(error){ 
        console.error(error);
    }
}

const postTransaction = async(formData) => { 
    try{ 
        return await fetch(`${api}/api/policies/transaction`, { 
            method: "POST", 
            body: formData
        })
    }catch(error){ 
        console.error(error)
        return false;
    }
}

const getTransactions = async(policy_id) => { 
    try{ 
        return await fetch(`${api}/api/policies/transaction?policy_id=${policy_id}`);
    }catch(error){ 
        console.error(error); 
        return false;
    }
}

const deleteTransaction = async(transaction_id) => { 
    try{ 
        return await fetch(`${api}/api/policies/transaction?transaction_id=${transaction_id}`, { 
            method : "DELETE", 
            headers: { 
                "Content-Type": "application/json"
            }
            
        })
    }catch(error){ 
        console.error(error); 
        return false;
    }
}

const getNextPrevHousehold = async(action, household_id, user_id) => { 
    try {
        return await fetch(`${api}/api/household/controller?household_id=${household_id}&action=${action}&user_id=${user_id}`); 
    } catch (error) {
        console.error(error); 
        return false;
    }
}


const moveToSharkTank = async(household_id) => { 
try {
    return await fetch(`${api}/api/household/unassign`,{ 
        method: "POST", 
        headers: { "Content-Type" : "application/json"} , 
        body: JSON.stringify({'household_ids': household_id})
        }
    )
} catch (error) {
    console.error(error); 
    return false; 
    }
}

const countCall = async(user_id) => { 
    try{ 
        return await fetch(`${api}/api/call/log`, { 
            method: "POST", 
            headers: { 
                "Content-Type": "application/json"
            }, 
            body: JSON.stringify({'user_id': user_id})
        })
    }catch(error){ 
        console.error(error); 
        return false;
    }
}

const getDailyCallStats = async(user_id) => { 
    try{ 
        return await fetch(`${api}/api/calls/daily?user_id=${user_id}`);
    }catch(error){ 
        console.error(error); 
        return false;
    }
}
module.exports = { 
    postAnnouncement, 
    getAnnouncement, 
    getCount, 
    getFollowUps, 
    patchCompleteTasks, 
    postOrganization, 
    editHouseholdInfo, 
    setPrimaryDriver, 
    updatePolicy, 
    getChildOrgs, 
    getPoliciesByUserId, 
    postTransaction, 
    getTransactions, 
    deleteTransaction, 
    getNextPrevHousehold, 
    moveToSharkTank, 
    countCall,
    getDailyCallStats
}

