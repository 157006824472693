import React, {useRef} from 'react'

function Change_status({setCurrentPopup, checkedHouseholds, api, fetchData, currentPage, searchTerm, toDate, fromDate}) {

  const status = useRef(null)

  const postStatus = async() => { 
    const arryOfHouseholdIds = [...checkedHouseholds];
    const response = await fetch(`${api}/api/household/status_title`, { 
        method: "POST", 
        headers: { 
            "Content-Type": "application/json"
        }, 
        body: JSON.stringify({ 
          checkedHouseholds: arryOfHouseholdIds, 
          status: status.current.value
        })
    })

    return response
  }


  const updateStatus = async() => { 

    if(checkedHouseholds.size === 0) return alert("No households are selected!");
    const response = await postStatus();
    if(response.status === false){ 
        return alert('Error updating status')
    }
    await fetchData(currentPage, searchTerm, toDate, fromDate);
    setCurrentPopup(null);

  }
    

  return (
    <> 
    <div className='background-color-full-dark border-radius-10px  popup z-index'  >
         <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick= { () => setCurrentPopup(null)}>❌</button></div>
             <div className='flex-container-el'> 
                <h3 className='padding-10px'>Change Status</h3>
                <select className='background-color-full-dark padding-10px no-border mediumtext button-hover max-width ' ref = {status}>
                    <option value = "Prospect">Prospect</option>
                    <option value = "Current Customer">Current Customer</option>
                    <option value = "Former Customer">Former Customer</option>
                </select>
             <button type = "button" className='button-default green-submit-btn margin-top-20px max-width padding-10px' onClick = {()=> updateStatus()}>Change Status</button>
             
         </div>
     </div>
    </>
  )
}

export default Change_status
