
import {React, useRef} from 'react'
import Cookies from 'js-cookie'

function QuotePopup({api, setCurrentPopup, currentHouseholdId, setSwitchNumber, getHouseholdData}) {

  const c_number = useRef();
  const is = useRef();
  const insurance_company = useRef();
  const length_with_carrier = useRef();
  const current_bi_limits = useRef();
  const amount_of_drivers = useRef();
  const year_home_built = useRef();
  const amount_of_cars = useRef();
  const easy_pay = useRef();
  const paid_in_full = useRef();

  const CREATE_QUOTE_OBJ = () =>{ 
    if(c_number.current.value && is.current.value && insurance_company.current.value && length_with_carrier.current.value && current_bi_limits.current.value 
      && amount_of_drivers.current.value && year_home_built.current.value && amount_of_cars.current.value && easy_pay.current.value && paid_in_full.current.value){ 
        let QUOTE_OBJ = {
          household_id : currentHouseholdId,
          is_column : is.current.value,
          insurance_company : insurance_company.current.value, 
          length_with_carrier : length_with_carrier.current.value,
          bi_limits : current_bi_limits.current.value,
          amount_of_drivers : amount_of_drivers.current.value,
          year_home_built: year_home_built.current.value,
          easy_pay: easy_pay.current.value,
          paid_in_full: paid_in_full.current.value, 
          "c_number": c_number.current.value, 
          amount_of_cars: amount_of_cars.current.value
        } 
        return QUOTE_OBJ;
      } else { 
        alert('Missing Input field');
        return false ;
      }

    
  }

  const POST_QUOTES = async(QUOTE_OBJ) => { 
    const response = await fetch(`${api}/api/household/quotes?household_id=${currentHouseholdId}&user_id=${Cookies.get('userid')}`, {
      method: "POST",

      headers : { 
        'Content-Type': 'application/json',
      } ,
      body : JSON.stringify(QUOTE_OBJ)
     })
     return response;
  }


  const CREATE_QUOTE_BTN = async() => { 
    const QUOTE_OBJ = CREATE_QUOTE_OBJ();
    if(QUOTE_OBJ){ 

   
      const response = await POST_QUOTES(QUOTE_OBJ); 
      if(response.status === 500){ 
        alert('Error Creating Quote!');

      }else { 
       await getHouseholdData();
        setCurrentPopup(null);
      }
    }
  }

  return (
    <> 
  <div className='background-color-full-dark border-radius-10px popup z-index ' >
  <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick= {() => setCurrentPopup(null)}  >❌</button></div>
    <div className='flex-container-el padding-20px'> 
      <h3 className='text-align-left'> Add a New Quote</h3>
        <label for = "firstname" className='background-color-full-dark text-align-left  '>C#</label>
          <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px  max-width' autocomplete="off" ref={c_number} />
          <label for = "firstname" className='background-color-full-dark text-align-left  '>IS</label>
          <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px  max-width'  autocomplete="off" ref={is} />
          <label for = "lastname" className='background-color-full-dark text-align-left margin-top-10px '>Insurance Company</label>
          <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px  max-width'  autocomplete="off" ref={insurance_company}/>
          <label for = "relation" className='background-color-full-dark text-align-left margin-top-10px'>Length with carrier</label>
          <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px  max-width'  autocomplete="off" ref= {length_with_carrier}/>
          <label for = "dateOfBirth" className='background-color-full-dark text-align-left margin-top-10px'>Current BI Limits</label>
          <input type = "text" className='background-color-full-dark regularTextInput margin-top-5px  max-width'  autocomplete="off" ref= {current_bi_limits}/>
          <label for = "dateOfBirth" className='background-color-full-dark text-align-left margin-top-10px'>Amount of drivers</label>
          <input type = "text" className='background-color-full-dark regularTextInput margin-top-5px  max-width'  autocomplete="off" ref= {amount_of_drivers}/>
          <label for = "dateOfBirth" className='background-color-full-dark text-align-left margin-top-10px'>Year Home Built</label>
          <input type = "text" className='background-color-full-dark regularTextInput margin-top-5px  max-width'  autocomplete="off" ref={year_home_built}/>
          <label for = "dateOfBirth" className='background-color-full-dark text-align-left margin-top-10px'>Amount of cars</label>
          <input type = "text" className='background-color-full-dark regularTextInput margin-top-5px  max-width' autocomplete="off" ref={amount_of_cars}/>
          <label for = "dateOfBirth" className='background-color-full-dark text-align-left margin-top-10px'>Easy Pay</label>
          <input type = "text" className='background-color-full-dark regularTextInput margin-top-5px  max-width' autocomplete="off" ref={easy_pay} />
          <label for = "dateOfBirth" className='background-color-full-dark text-align-left margin-top-10px'>Paid in Full</label>
          <input type = "text" className='background-color-full-dark regularTextInput margin-top-5px  max-width'  autocomplete="off" ref={paid_in_full}/>
          
          <button type = "button" class = "button-default green-submit-btn margin-top-20px max-width" onClick = {() =>  CREATE_QUOTE_BTN()}>Add New Quote</button>
    </div>
  </div>
    
    </>
  )
}

export default QuotePopup

