import React from 'react'

function EditUser({setEditUserPopup, userToEditData, api, fetchUsers}) {


  const updateUser = () => { 
      let inputObj = {"id" : userToEditData.id};
    const allInputElements = document.querySelectorAll('.user_input');
    allInputElements.forEach(element => { 
      inputObj[element.name] = element.value


    });
    return inputObj;
  }



  const fetchUpdateUser = async() => { 
    const updatedUserObj = updateUser();
    const response = await fetch(`${api}/api/users`, { 
      method: "PUT", 
      headers: { 
        'Content-Type' : "application/json",
      }, 
      body: JSON.stringify({ updatedUserObj})
      
    }).then(response => { 
      if(!response.ok){ 
        throw new Error('Error updating policy');
      }else { 
        fetchUsers();
        setEditUserPopup(false)
      }
    }).catch(error => { 
      alert(error);
    })

  }
  return (
    <div className='background-color-full-dark border-radius-10px popup' id = "edit-user"  >
    <div className = 'black-background  text-align-right border-radius-top-10px '><button type = "button" className='popup-exit-button' onClick={() => setEditUserPopup(false)} >❌</button></div>
    <div className='flex-container-el padding-20px'> 
    <h3 className='text-align-left'>Edit User Information</h3>
    <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5px '>Username</label>
    <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px   max-width   user_input' name = "username" autocomplete="off" defaultValue = {userToEditData.username}/>
    <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5px '>New Password</label>
    <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px   max-width  user_input ' name = "password" autocomplete="off" />
    <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>First Name</label>
    <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px  max-width  user_input ' name = "first_name" autocomplete="off" defaultValue = {userToEditData.firstname}/>
    <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>Last Name</label>
    <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px  max-width  user_input ' name = "last_name" autocomplete="off" defaultValue = {userToEditData.lastname}/>
    <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>Role</label>
    <select className='background-color-full-dark regularTextInput margin-top-5px r max-width user_input ' name = "role" autocomplete="off" defaultValue = {userToEditData.role}>
          <option value = "Admin">Admin</option>
          <option value = "Supervisor">Supervisor</option>
          <option value = "Agent">Agent</option>
          <option value = "Customer Service">Customer Service</option>
    </select>
    <label for = "lastname" className='background-color-full-dark text-align-left margin-top-5px  margin-top-5px '>Email</label>
    <input type = 'text' className='background-color-full-dark regularTextInput margin-top-5px  max-width user_input  ' name = "email" autocomplete="off" defaultValue = {userToEditData.email}/>
    <button type = "button" class = "button-default green-submit-btn margin-top-10px max-width " id = "update-user-data" onClick={()=> fetchUpdateUser() } >Update</button>
    </div>
  </div>
  )
}

export default EditUser
