import React, {useEffect, useState} from 'react'
import Cookies from 'js-cookie'
import PoliciesRelatedToUserPopup from './policy_popups/PoliciesRelatedToUserPopup';
const {getDailyCallStats} = require('./fetches/fetch');
function Dashboard({api,  currentUserData, userIdAndFullName, setSwitchNumber, setCurrentHouseholdId }) {
  const [numberOfQuotes, setNumberOfQuotes] = useState();
  const [monthlyPrem, setMonthlyPrem] = useState(); 
  const [uw_action_count, set_uw_action_count] = useState();
  const [policyCount, setPolicyCount] = useState();
  const [items, setItems] = useState();
  const [dailyCalls, setDailyCalls] = useState();
  const [togglePoliciesPopup, setTogglePoliciesPopup] = useState(false);
  
  useEffect(()=> { 
    
    const async = async()=> { 
      const prem = await get_monthly_prems ();
      setMonthlyPrem(prem[0]?.total_monthly_prem);
      const quotes = await get_quotes();
      setNumberOfQuotes(quotes[0])
      const numOfPolicies = await getActivePolicies();
      setPolicyCount(numOfPolicies[0])
      const num_of_items = await getNumOfItems()
      setItems(num_of_items[0]);
      const uwaction = await get_uwaction_count(); 
      set_uw_action_count(uwaction[0]);
      const dailyCallsRes = await getDailyCalls() 
      setDailyCalls(dailyCallsRes[0]);
      
    }
    async();


    
  },[])
  



  const calculateCloseRatio = (policyCount, numberOfQuotes) => {
    if(policyCount  === 0 || numberOfQuotes ===0){ 
      
      return undefined;
    }
    const response = (policyCount / numberOfQuotes) * 100;
    return Math.floor(response);
  }

  const get_quotes = async() => { 
   
     
    const response = await fetch(`${api}/api/dashboard/quotes?user_id=${Cookies.get('userid')}`)
    const data = await response.json();
    return data;
  
  }
  const getDailyCalls = async() => { 
    const dailyCallsRes = await getDailyCallStats(Cookies.get('userid')); 
    const data = await dailyCallsRes.json();
    return data
  }
  const get_monthly_prems = async() => { 
    const response = await fetch(`${api}/api/dashboard/premiums?user_id=${Cookies.get('userid')}`)
    const data = await response.json();
    return data
  }
  const get_uwaction_count = async() => { 
    const response = await fetch(`${api}/api/dashboard/uwactions?user_id=${Cookies.get('userid')}`)
    const data = await response.json();
    return data;
  }
  const getActivePolicies = async() => { 
    const response = await fetch(`${api}/api/dashboard/policies?user_id=${Cookies.get('userid')}`)
    const data = await response.json();
    return data;
  }
  const getNumOfItems = async() => { 
    const response = await fetch(`${api}/api/dashboard/items?user_id=${Cookies.get('userid')}`)
    const data = await response.json();
    console.log(data)
    return data
  }
 
  return (
    <>
    {togglePoliciesPopup && <PoliciesRelatedToUserPopup setTogglePoliciesPopup = {setTogglePoliciesPopup} userIdAndFullName = {userIdAndFullName} setSwitchNumber={setSwitchNumber} setCurrentHouseholdId = {setCurrentHouseholdId}/>}
    <div>
     <h3 className='padding-10px' >Preformance</h3>
      <div name = "upper_stats" className='padding-10px grid-container-dashboard margin-top-10px'> 
   
        <div className= "stat">
            <h2 className={`fade-in  cursor-pointer`} onClick = {() => setTogglePoliciesPopup(true)}>${monthlyPrem ? monthlyPrem?.toLocaleString() : 0}</h2>
            <h4>Monthly Premium Total</h4>
        </div>
        <div className= "stat">
            <h2 className={`fade-in `}>{policyCount && monthlyPrem && calculateCloseRatio(policyCount.num_of_policies, numberOfQuotes.total_rows)  > 0? calculateCloseRatio(policyCount.num_of_policies, numberOfQuotes.total_rows) : 0}%</h2>
            <h4>Close Ratio</h4>
        </div>
        <div className= "stat">
            <h2 className={`fade-in  ${numberOfQuotes ? '': 'blurred'}`}>{numberOfQuotes ? numberOfQuotes?.total_rows : 0}</h2>
            <h4>Quotes</h4>
        </div>
        <div className= "stat">
            <h2 className={`fade-in  ${policyCount ? '': 'blurred'}`}>{policyCount ? policyCount?.num_of_policies: 0 } </h2>
            <h4>Active Policies</h4>
        </div>
        <div className= "stat">
                 <h2 className= {`fade-in  ${uw_action_count ? '': 'blurred'}`}>{uw_action_count ? uw_action_count?.uw_action_count : 0}</h2>
                <h4>UW Actions Required</h4>
        </div>
        <div className= "stat" >
                 <h2 className={`fade-in  ${items  ? '': 'blurred'}`}>{  items && items.items > 0 ? items?.items : 0}</h2>
                <h4>Items</h4>
        </div>
        <div className= "stat">
                <h2 className='fade-in'>0</h2>
                <h4>New Text SMS</h4>
        </div>
        <div className= "stat">
                <h2 className='fade-in'>0</h2>
                <h4>New Email</h4>
        </div>
        <div className= "stat ">
            <h2 className='fade-in'>{dailyCalls && dailyCalls.Calls}</h2>
            <h4>Daily Call Volume</h4>
        </div>
    </div>
      <div name = "upcoming_comissions "> 
        <h3 className='margin-top-20px padding-10px'>Communication</h3>
      </div>
    </div>
    </>
  )
}

export default Dashboard
